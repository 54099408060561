<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="$t('catalog.title')" :subtitle="$t('catalog.list.title')" />
    <div class="page-content container-fluid settings">
      <!-- Add product (sidebar) -->
      <b-sidebar id="sidebar-right-catalog" :title="$t('catalog.create')" no-header right backdrop shadow>
        <div class="col sidebar-header">
          <p>{{$t('catalog.create')}}</p>
          <span v-b-toggle.sidebar-right-catalog class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
          <span v-b-toggle.sidebar-right-catalog class="material-symbols-outlined sidebar-close">close</span>
        </div>
        <div class="col desc-column">
          <p>{{$t('catalog.register.desc')}}</p>
        </div>
        <form class="add-prod-form col mb-0">
          <!-- Name -->
          <div class="input-group">
            <label for="">{{$tc('app.name', 1)}} <span class="material-symbols-outlined"> info </span></label>
            <input placeholder="" type="text" class="form-control" />
          </div>

          <!-- Description -->
          <div class="input-group">
            <label for="">{{$t('app.description')}} <span class="material-symbols-outlined"> info </span></label>
            <input placeholder="https://" type="text" class="form-control" />
          </div>
        </form>
      </b-sidebar>
      <!--/Add product (sidebar) -->
      <!-- Edit product (sidebar) -->
      <b-sidebar id="sidebar-right-edit-catalog" :title="$t('catalog.list.edit')" no-header right backdrop shadow>
        <div class="col sidebar-header">
          <p>{{$t('catalog.list.edit')}}</p>
          <span v-b-toggle.sidebar-right-edit-catalog class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
          <span v-b-toggle.sidebar-right-edit-catalog class="material-symbols-outlined sidebar-close">close</span>
        </div>
        <div class="col desc-column">
          <p>{{$t('catalog.register.desc')}}</p>
        </div>
        <form class="add-prod-form col mb-0">
          <!-- Name -->
          <div class="input-group">
            <label for="">{{$tc('app.name', 1)}} <span class="material-symbols-outlined"> info </span></label>
            <input placeholder="" type="text" class="form-control" />
          </div>

          <!-- Description -->
          <div class="input-group">
            <label for="">{{$t('app.description')}} <span class="material-symbols-outlined"> info </span></label>
            <input placeholder="" type="text" class="form-control" />
          </div>
        </form>
      </b-sidebar>
      <!--/Edit product (sidebar) -->
      <div class="row">
        <!-- Search column -->
        <div class="col-6 mx-auto mb-5">
          <div class="form-wrapper">
            <form @submit.prevent="search" class="form-group col px-0 mb-0">
              <div class="input-group">
                <input
                  placeholder=""
                  type="text"
                  class="form-control"
                  :v-model="searchQuery"
                  ref="searchInput"
                />
                <div class="input-group-prepend">
                  <button @click="search" type="button" class="btn icon_btn m-0">
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--/Search column -->
      </div>
      <!-- Add List -->
      <div class="container container-add">
        <div class="row">
          <div class="col-12">
            <div class="card card-add-list">
              <div id="close-dialog" class="btn-close" @click="closeDialog"></div>
              <div class="card-body">
                <div class="col-8 d-flex justify-content-center flex-column">
                  <p class="card-text" v-html="$t('catalog.list.add-list-desc')"></p>
                  <div class="w-100"></div>
                  <button v-b-toggle.sidebar-right-catalog class="btn btn-yup btn-add">{{$t('catalog.list.btn-add')}}</button>
                </div>
                <div class="col d-flex justify-content-center align-items-center">
                  <div class="img-wrapper">
                    <img v-b-toggle.sidebar-right-catalog src="@/assets/add-dashed-circle.gif" alt="Add" width="167">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/Add List -->
      <!-- My Catalogs -->
      <div class="container container-catalogs">
        <div class="row mb-4">
          <div class="col-12 d-flex">
            <span class="h5"><b>{{$t('catalog.list.my-cat')}}</b></span>
            <!-- <ul class="list-unstyled options-cat">
              <li><span class="material-symbols-outlined"> filter_list </span></li>
              <li class="options-item" :class="[viewType === 'grid' ? 'active' : '']"><span class="material-symbols-outlined" role="button" @click="changeType('grid')"> grid_view </span></li>
              <li class="options-item" :class="[viewType === 'list' ? 'active' : '']"><span class="material-symbols-outlined" role="button" @click="changeType('list')"> list </span></li>
            </ul> -->
          </div>
        </div>
        <!-- Title -->
        <div class="row mb-4">
          <div class="col-12 col-sm"><span class="title-list">{{$tc('app.name', 1)}}</span></div>
          <div class="col-12 col-sm"><span class="title-list">{{$t('app.description')}}</span></div>
          <div class="col-12 col-sm"><span class="title-list">{{$tc('generic-str.category', 1)}}</span></div>
          <div class="col-12 col-sm-2"><button v-b-toggle.sidebar-right-catalog class="btn btn-outline-primary btn-add-list mx-sm-auto">{{$t('catalog.create')}} <span class="material-symbols-outlined"> add </span></button></div>
          <hr class="purple">
        </div>
        <!--/Title -->
        <!-- Content -->
        <div class="row mb-4">
          <template v-for="index in 10">
            <div class="content-row col-12" :key="index">
              <div class="col-12 col-sm"><router-link to="/catalog/list/registered"><span class="title-list">Catálogo WhatsApp</span></router-link></div>
              <div class="col-12 col-sm"><span class="title-list">Descrição info....</span></div>
              <div class="col-12 col-sm">
                <ul class="list-unstyled tag-list">
                  <li class="badge badge-readed">Acessórios</li>
                  <li class="badge badge-readed">Roupas</li>
                  <li class="badge badge-readed">Sapatos</li>
                  <li class="badge badge-readed">Outros</li>
                </ul>
              </div>
              <div class="col-12 col-sm-2">
                <ul class="list-unstyled options-content">
                  <li><span class="material-symbols-outlined" v-b-toggle.sidebar-right-edit-catalog> edit </span></li>
                  <li><span class="material-symbols-outlined"> control_point_duplicate </span></li>
                  <li><b-dropdown right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <span class="material-symbols-outlined"> more_horiz </span><span class="sr-only">Search</span>
                    </template>
                    <b-dd-item href="#" v-b-toggle.sidebar-right-edit-catalog><span class="material-symbols-outlined"> edit </span>Editar</b-dd-item>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> control_point_duplicate </span>Duplicar</b-dd-item>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> archive </span>Arquivar</b-dd-item>
                    <b-dd-item href="#"><span class="material-symbols-outlined"> info </span>Mais detalhes</b-dd-item>
                  </b-dropdown></li>
                </ul>
              </div>
            </div>
          </template>
        </div>
        <!--/Content -->
        <!-- Pagination -->
        <!-- static pagination (only for preview purpose) -->
        <div id="bs4-table_paginate" class="dataTables_paginate paging_simple_numbers">
            <ul class="pagination">
                <li id="bs4-table_previous" class="paginate_button page-item previous disabled">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="0" tabindex="0" class="page-link">
                        <span class="material-icons-outlined"> arrow_back_ios </span>
                    </a>
                </li>
                <li class="paginate_button page-item active">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="1" tabindex="0" class="page-link">1</a>
                </li>
                <li class="paginate_button page-item">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                </li>
                <li id="bs4-table_next" class="paginate_button page-item next">
                    <a href="javascript:void(0)" aria-controls="bs4-table" data-dt-idx="7" tabindex="0" class="page-link">
                        <span class="material-icons-outlined"> arrow_forward_ios </span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- <pagination :lastPage="pages" @change="fetch" /> -->
        <!--/Pagination -->
      </div>
      <!-- My Catalogs -->
    </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
// import Pagination from '@/components/rebranding/Pagination.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
// import WhatsService from '@/services/whatsapp.service';
// import Darkmode from '@/components/rebranding/SetTheme.vue';

export default {
  name: 'Catalog',
  components: {
    PageHeader,
    // Pagination
    // HiddenInput,
    // Darkmode,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/catalog',
        2: '/catalog/list',
      },
      fetched: false,
      searchQuery: null,
      // pages: 1,
      viewType: 'list',
    };
  },
  methods: {
    search() {
      this.fetched = false;
      console.info('Search function');
      /* const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      SmsService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          // console.log('Templates: ', this.blacklists);
          result = this.blacklists.filter((item) => word.toLowerCase().split(' ').every((v) => item.mobile_number.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.blacklists = result;
        },
        (error) => {
          this.content = error;
        },
      ); */
    },
    /* fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      MailService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          console.log('MailService.getBlacklists', this.blacklists, response.last_page);
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    }, */
    closeDialog() {
      // document.getElementById('close-dialog').addEventListener('click', closeBubble);
      document.querySelectorAll('.container-add').forEach((el) => el.remove());
    },
    changeType(type) {
      this.viewType = type;
    },
  },
};
</script>
<style scoped lang="scss">
/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.container-add, .container-catalogs {
  max-width: 1740px;
}
.card-add-list {
  background-color: var(--clr-yup-purple);
  overflow: hidden;
  position: relative;
  height: 264px;
  border-radius: 8px;
  margin-bottom: 4rem;

  >.card-body {
    display: flex;
  }

  .card-text {
    color: #fff;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }

  .img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 437px;
    height: 437px;
    background-color: #a941fa;
    border-radius: 50%;
    z-index: 0;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: #A941FA;
    color: #FFF;
    font-weight: 400;
    font-size: 1rem;
  }
}

/* List */
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding: 8px 20px;
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
  .options-item.active {
    span {
      color: var(--clr-yup-purple);
    }
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover, &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6B23DC3D;
  border-radius: 75px;
}
.content-row {
  display: flex;
  background-color: var(--background-2);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    align-items: center;
    min-height: 72px;
  }
  @media (min-width: 768px) {
    div:last-child {
      justify-content: center;
    }
  }
}
.options-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin-bottom: unset;
  li, .dropdown-toggle span {
    color: var(--gray-font-color-5);
  }
  .dropdown-toggle:hover, .dropdown-toggle:focus {
    color: var(--gray-font-color);
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #FFF;
  transform: scale(1.6);
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: unset;
  li {
    color: var(--gray-font-color-5);
  }
}
::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
/* Sidebar add prod */
::v-deep #sidebar-right-catalog, ::v-deep #sidebar-right-edit-catalog {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  // Default sidebar header
  .b-sidebar-header{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;
    p {
      font-weight: 500;
      font-size: 1.25rem;
    }
    span {
      color: var(--gray-font-color-5);
    }
    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }
    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }
  .b-sidebar-body {
    padding: 0 1.5rem;
  }
  header {
    >strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
.add-prod-form {
  .input-group {
    margin-bottom: 2rem;
  }

  label {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: .725rem;
    font-size: 1rem !important;

    span {
      color: var(--gray-font-color-3);
    }
  }
}
/* Import img */
.import-wrapper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border: 2px dashed var(--clr-yup-purple);
  border-radius: 12px;
  /* margin: 0 10%; */
  padding: 1rem 0;
  @media (max-width: 767.98px) {
    margin: 0;
  }
  p {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
  }
  img.csv-img, .custom-file-label-modal {
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .icon-img {
    margin: 0 auto;
    margin-bottom: 1rem;
    color: var(--gray-font-color);
  }
  .custom-file-label-modal {
    background-color: #FFF;
    color: var(--clr-yup-purple);
    padding: .75rem;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    transition: .5s;
    position: relative;
    z-index: 2;
    &:hover, &:focus {
      border-color: var(--clr-yup-purple-hover) !important;
      background-color: var(--clr-yup-purple-hover) !important;
      color: #fff !important;
    }
  }
  #preview {
  display: flex;
  justify-content: center;
  align-items: center;
  }

  #preview img {
    /* max-width: 100%; */
    max-width: 60%;
    max-height: 500px;
  }
}
.custom-file-input {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;
  cursor: default;
}
/*/Sidebar add prod */
</style>
